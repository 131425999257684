<template>
<div class="main-header" :class="{'d-none': g_game_state === 'flow'}">
  <b-container class="d-flex align-items-center main-header_container">
    <router-link class="d-none d-sm-flex align-items-center" to="/">
      <img width="42" height="42" :src="require('@/assets/logo.svg')" alt="logo">
      <span class="logo-text ml-3">
        Tigpok
      </span>
    </router-link>

    <router-link class="d-block d-sm-none" to="/">
      <img width="32" height="32" :src="require('@/assets/logo.svg')" alt="logo">
    </router-link>

    <div class="ml-auto d-flex align-items-center">
      <b-btn variant="link" class="link-gray-100 d-flex align-items-center px-0 py-0 mr-2" v-b-modal.modal-rules>
        <span class="d-block d-lg-none">
          <b-icon class="h-100" icon="question" />
        </span>
        <span class="d-none d-lg-block">
          Rules
        </span>
      </b-btn>

      <router-link to="/payments" class="ml-1 link-gray-100 d-flex align-items-center">
        <span class="d-flex align-items-center">
          <img class="mr-1" width="20" :src="require('@/assets/token-icon.svg')" alt="">
          {{USER_ME.coins}}
          </span>
        <span class="text-primary ml-1">+</span>
      </router-link>

      <b-btn class="ml-3 link-white px-2 pt-1 d-flex" variant="link" to="/dashboard">
        <div class="h-100">
          <b-icon class="mr-1" icon="person" />
        </div>

        <span class="d-none d-md-inline-block main-header_nick">
          {{USER_ME.nick_name}}
        </span>
      </b-btn>

      <b-btn @click="$store.dispatch('LOG_OUT')" class="link-gray-400-danger px-2 ml-3" variant="link">
        <b-icon icon="box-arrow-right" />
      </b-btn>
    </div>
  </b-container>

  <b-modal size="lg" id="modal-rules" :content-class="($store.state.isWhiteTheme ? 'modal-white' : '')" hide-header title="BootstrapVue">
      <h3>Game rules</h3>
      <p class="mb-1 text-gray-100">Each game involves three players. Each player makes the same bet.</p>
      <p class="mb-1 text-gray-100">The game consists of two stages: negotiations and voting.</p>
      <p class="mb-1 text-gray-100">During the negotiation phase, each player has a limited time to video chat with each of the other players. While two players are negotiating, the third player is waiting for his turn.</p>
      <p class="mb-1 text-gray-100">After the player negotiates with each opponent, the voting stage begins for him. Every player can vote against one of the opponents or against all of them.</p>
      <p class="mb-1">The results of the game are determined based on the voting results:</p>
      <ol class="mb-1">
        <li class="text-gray-100">A player takes the bets of both opponents if both opponents voted against him and he voted against all opponents.</li>
        <li class="text-gray-100">A player takes the bet of the opponent he voted against if that opponent voted against all and the other opponent voted against all or against the player.</li>
        <li class="text-gray-100">A player shares an opponent's bet equally with another player if those two players voted against the opponent and the opponent did not vote against all.</li>
        <li class="text-gray-100">A player loses his bet if both opponents vote against him and he does not vote against all opponents.</li>
        <li class="text-gray-100">A player loses his bet if he voted against all opponents, but only one opponent voted against the player.</li>
        <li class="text-gray-100">All players lose their bets if all of them voted against all.</li>
        <li class="text-gray-100">In all other cases the player returns his bet.</li>
      </ol>
      <p>
        If any player disconnects from the game before the end of voting, the game is considered canceled and bets are returned to the players.
        For each completed game, the platform takes a commission of 3% of each bet.
      </p>

    <template #modal-footer="{ cancel }">
      <div class="d-flex">
        <b-btn variant="primary" @click="cancel()">Close</b-btn>
      </div>
    </template>
  </b-modal>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters(['USER_ME', 'g_game_state'])
}
}
</script>