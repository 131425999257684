import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import qs from 'qs'
import fs from 'file-saver'
import './components'
import 'animate.css';

import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";

import '@adyen/adyen-web/dist/adyen.css';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(qs)
Vue.use(VueAxios, axios)
Vue.use(Flicking);
// Vue.use(fs)

Vue.config.productionTip = false

// let baseUrl = 'http://192.168.1.70:3000/api/v1/'
let baseUrl = 'https://45-126-125-217.cloud-xip.com:8443/api/v1/'

axios.defaults.baseURL = baseUrl

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.qs = qs
window.fs = fs