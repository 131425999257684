<template>
<div class="tp-input-icon_wrap">
  <span v-if="icon === 'usdt'" class="tp-icon-input_icon">
    <svg enable-background="new 0 0 226.777 226.777" height="18" width="18" id="Layer_1" version="1.1" viewBox="0 0 226.777 226.777" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M226.883,107.66c0-14.245-38.761-26.13-90.27-28.872l-0.004-22.697h64.315V8.855H25.06v47.236h64.31v22.808  C38.799,81.829,0.97,93.59,0.97,107.66c0,14.072,37.829,25.834,88.4,28.759v81.504h47.239V136.53  C188.122,133.794,226.883,121.905,226.883,107.66z M113.926,121.693c-55.288,0-100.109-8.557-100.109-19.119  c0-8.943,32.139-16.451,75.553-18.54v5.383h0.005v22.059c7.827,0.439,16.053,0.669,24.551,0.669c7.825,0,15.424-0.199,22.688-0.563  V83.952c44.355,1.961,77.423,9.548,77.423,18.622C214.036,113.136,169.214,121.693,113.926,121.693z" fill="#231F20"/>
    </svg>
  </span>

  <b-icon v-else class="tp-icon-input_icon" :icon="icon" />
  <input class="tp-icon-input_input" :value="internalValue" v-bind="$attrs" @input="updateValue($event.target.value)">
</div>
</template>



<script>
export default {
  name: "tp_icon_input",
  props: {
    icon: String,
    value: String
  },

  watch: {
    value(newVal) {
      this.internalValue = newVal;
    }
  },

  data() {
    return {
      internalValue: this.value
    }
  },

  methods: {
    updateValue(newValue) {
      this.internalValue = newValue; // Update the local value
      this.$emit('input', newValue); // Emit an event to notify the parent
    }
  }
}
</script>

<style scoped>

</style>