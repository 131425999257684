import Vue from 'vue'
import lowerCase from 'lodash/lowerCase'

const requireComponent = require.context(
    '@/components', true, /\.vue$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)

    const componentName = lowerCase(
        fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
    ).replace(/\s+/g, '-')

    Vue.component(componentName, componentConfig.default || componentConfig)
})