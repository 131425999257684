<template>
  <div class="h-100 overflow-auto" :class="{'dark-gradient-bg': !IS_WHITE_THEME}" style="scroll-snap-type: y mandatory">
    <div class="reload-warning d-flex justify-content-center flex-column align-items-center" v-if="cW < 769 && g_game_state !== ''">
      <p class="mb-0">
        Reload
      </p>

      <b-icon icon="chevron-double-down" />
    </div>

    <component :class="{'tigpok_white': IS_WHITE_THEME}" :is="layout" >
      <router-view class="flex-grow-1" default="{ name: 'Home' }" />
    </component>
  </div>
</template>

<script>
import main_layout from "./layouts/main_layout/main_layout.vue";
import auth_layout from "./layouts/auth_layout/auth_layout.vue";
import { mapGetters } from  "vuex"

export default {
  components: {
    "main-layout": main_layout,
    "auth-layout": auth_layout
  },

  computed: {
    ...mapGetters(['g_game_state', 'IS_WHITE_THEME']),

    layout() {
      return (this.$route.meta.layout || 'auth') + '-layout'
    },

    cW() {
      return document.documentElement.clientWidth
    },
  },

  created() {
    // window.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      let scroll = window.scrollY
      setTimeout(() => {
        if(scroll !== 0) {
          window.scrollTo(0, 75)
        }
      }, 500)
    },
  },

  mounted() {
    const tikpokThemeChache = localStorage.getItem('tigpok_is_white_theme')

    if(![null, undefined].includes(tikpokThemeChache)) {
      this.$store.dispatch('toogleIsWhiteTheme', JSON.parse(tikpokThemeChache))
    }
  }
}
</script>

<style lang="scss">
@import 'scss/app.scss';
</style>
