import axios from 'axios'
import router from '../../router'

export default({
  state: {
    user_me: '',
  },
  mutations: {
    SET_USER_ME: (state, data) => {
      state.user_me = data
    }
  },

  actions: {
    async LOGIN({dispatch}, credentials) {
      return new Promise((resolve, reject) => {
         axios({
          method: 'post',
          url: 'login/access-token',
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Access-Control-Allow-Origin': 'http://localhost'
          },
          data: window.qs.stringify({
            username: credentials.username,
            password: credentials.password
          }),
        })
            .then(({data}) => {
              localStorage.setItem('tigpok_login', 'true')
              localStorage.setItem('tigpok_token', data.access_token)
              axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`

              dispatch('ME')
              router.push('/')

              resolve()
            })
            .catch((err) => {
              reject(err)
            })
      })
    },

    async ME({commit, dispatch}) {
     return new Promise((resolve, reject) => {
      axios.get('users/me', {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('tigpok_token')}
      }).then((response) => {
        localStorage.setItem('tigpok_user_me', JSON.stringify(response.data))
        commit("SET_USER_ME", response.data)
        resolve(response)
      })
      .catch((error) => {
        router.push('/login')
        reject(error)
      })
     })
    },

    async SIGN_UP({}, data){
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: `users/open${data.group ? `?group=${data.group}` : ''}`,
                data: data.credentials,
            })
                .then((response) => {
                    router.push('/login')
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async LOG_OUT({dispatch}){
      localStorage.setItem('tigpok_login', 'false')
      localStorage.setItem('tigpok_token', '')
      dispatch('ME')
    }
  },
  getters: {
    USER_ME: (state) => state.user_me
  }
})
